<!--
 * @Author: your name
 * @Date: 2021-05-27 10:27:58
 * @LastEditTime: 2021-06-03 20:01:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\contentEngine\imageText.vue
-->

<template>
  <div class="overflow_scroll">
    <ul class="image_text_list">
      <li class="image_text_item" v-for="item in dataList" :key="item.id" @click.stop="handlePreview(item)">
        <div class="mr16">
          <p class="title">{{item.title}}</p>
          <div v-html="item.content" class="content_text text-ellipsis"></div>
        </div>
        <img class="cover" :src="item.cover?ossUrl+item.cover:'/imgs/avatar.png'" alt="">
      </li>
    </ul>
  </div>
</template>
<script>
import {reactive,toRefs } from 'vue'
import {useRouter} from 'vue-router'
import {ossUrl} from '@/utils/urls'
export default {
  props:{
    dataList:{
      required:true,
      type:Array,
      default:()=>[]
    }
  },
  setup() {
    const router = useRouter()
    const state=reactive({
      ossUrl
    })
    const handlePreview=(element)=>{
      // console.log(context,context.router)
      router.push({name:'PreviewHtml',query:{id:element.id}})
    }
    return {
      ...toRefs(state),
      handlePreview
    }
  }
}
</script>
<style lang="less" scoped>
  .image_text_list{
    :deep(a){
      pointer-events: none;
    }
    .image_text_item{
      background: #fafafa;
      border-radius: 24px;
      margin-bottom: 20px;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .title{
        font-size: 30px;
        font-weight: bold;
      }
      .content_text {
        display: inline-block;
        margin-top: 16px;
        &.text-ellipsis,&.text-ellipsis &:deep(*:not(img)){
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          max-width: 462px;
          height: 72px;
          line-height: 36px;
          overflow: hidden;
          white-space: pre-wrap;
          &:deep(img) {
            height: 21px;
            width: auto;
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .cover{
        flex-shrink: 0;
        width: 144px;
        height: 144px;
        border-radius: 12px;
      }
    }
  }
</style>