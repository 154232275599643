/*
 * @Author: your name
 * @Date: 2021-05-27 11:42:40
 * @LastEditTime: 2021-06-03 20:55:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\directive\longPress.js
 */
// 长按指令
export const longPress={
  created(el, binding){
    // const _this=binding.instance
  // 确保提供的表达式是函数
  if (typeof binding.value !== 'function') {
    // 将警告传递给控制台
    console.warn(`[longpress:] provided expression '${binding.expression}' is not afunction, but has to be `)
  }
  // 定义变量
  let pressTimer = null;
  // 定义函数处理程序
  // 创建计时器（ 1秒后执行函数 ）
  let start = (e) => {
    if (e.type === 'click' && e.button !== 0) {
      return
    }
    if (pressTimer === null) {
      pressTimer = setTimeout(() => {
        console.log('长按中...')
        // 执行函数
        handler(e)
      }, 1000)
    }
  }
  // 取消计时器
  let cancel = () => {
    // 检查计时器是否有值
    if ( pressTimer !== null ) {
      clearTimeout(pressTimer)
      pressTimer = null
    }
  }
  // 运行函数
  const handler = (e) => {
    // 执行传递给指令的方法
    binding.value(e)
  };  
  // 添加事件监听器
  el.addEventListener("mousedown", start)
  el.addEventListener("touchstart", start)
  // 取消计时器
  el.addEventListener("click", cancel)
  el.addEventListener("mouseout", cancel)
  el.addEventListener("touchend", cancel)
  el.addEventListener("touchmove", cancel)
  el.addEventListener("touchcancel", cancel)
  }
}