<template>
  <div class="content_engine">
    <search-input class="search" @search="getList"/>
    <group class='mt40 mb30' @changeGroup="getData"/>

    <van-tabs v-model:active="type" swipeable animated @change="handleChange">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.title" :name="item.name"></van-tab>
    </van-tabs>
    <van-skeleton :row="3" :loading="loading">
      <component :is="componentName" :dataList="dataList" ></component>
    </van-skeleton>
  </div>
</template>
<script>
import { reactive,toRefs,computed } from 'vue'
import {matchLabel} from '@/utils/commUtil'
import searchInput from '@/components/searchInput'
import group from '@/views/fastReply/group'
import {getMessage} from '@/api/fastReply'
import images from './images'
import imageText from './imageText'
import file from './file'
import {Tabs,Tab,Skeleton} from 'vant'
export default {
  name:'ContentEngine',
  components:{
    [Tabs.name]:Tabs,
    [Tab.name]:Tab,
    [Skeleton.name]:Skeleton,
    searchInput,
    group,
    images,
    imageText,
    file
  },
  setup() {
     const state=reactive({
      tabs:[
        {
          title:'图片',
          name:2,
          component:'images'
        },
        {
          title:'图文',
          name:3,
          component:'imageText'
        },
        {
          title:'文件',
          name:4,
          component:'file'
        }
      ],
      loading:false,
      type:2, //2-图片 3-图文 4-文件
      groupId:null,
      dataList:[]
     })
     const componentName=computed(()=>{
        return matchLabel(state.tabs,'name','component',state.type)
     })
     
    const getData=(groupId)=>{
      state.groupId=groupId
      getList()
    }
    const getList=(keyword)=>{
      const params={
        type: state.type,
        keyword,
        groupId: state.groupId
      }
      state.loading=true
      getMessage(params).then(res => {
        state.loading=false
        if (res&&res.body){
          state.dataList=res.body
        } else {
          state.dataList=[]
        }
      })
    }
    const handleChange=(name)=>{
      state.type=name
      getList()
    }
    return {
      ...toRefs(state),
      componentName,
      getList,
      getData,
      handleChange
    }
  }
}
</script>
<style lang="less" scoped>
  .content_engine{
    padding: 8px 32px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
    user-select: none;
    :deep(.van-tabs) {
      margin-bottom: 32px;
    }
    :deep(.overflow_scroll){
      height: calc(~"100% - 300px");
      overflow: auto;
      &.active{
        height: calc(~"100% - 500px");
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
</style>