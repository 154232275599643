<template>
  <div class="file">
    <ul class="file_list">
      <li v-for="item in dataList" :key="item.id" class="file_item" @click="sendMsg(item)">
        <img class="fileIcon" :src="formatFileIcon(item)" alt="">
        <span>{{item.title}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
// import {reactive,toRefs} from 'vue'
import {matchLabel} from '@/utils/commUtil'
import {fileTypeList} from '@/utils/const'
import {sendChatMessage} from '@/utils/weChatConfig'
import {useStore} from 'vuex'
export default {
  props:{
    dataList:{
      required:true,
      type:Array,
      default:()=>[]
    }
  },
  setup(){
    const store=useStore()
    const formatFileIcon=(file)=>{
      const index = file.title.lastIndexOf('.')
      const suffix = file.title.substr(index+1)
      const src=matchLabel(fileTypeList, 'label', 'icon', suffix)
      return src
    }
    const sendMsg=(element)=>{
      store.commit('common/setLoading',true)
      sendChatMessage('file',element.content,element.title).then(()=>{
        store.commit('common/setLoading',false)
      })
    }
    return {
      formatFileIcon,
      sendMsg
    }
  }
}
</script>
<style lang="less" scoped>
  .file{
    .file_list{
      .file_item{
        padding: 34px 0;
        font-size: 28px;
        &:not(:last-of-type){
          border-bottom: 1px solid #eee;
        }
        .fileIcon{
          width: 32px;
          height: 32px;
          margin-right: 16px;
          vertical-align: middle;
        }
      }
    }
  }
</style>