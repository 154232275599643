<template>
 <!-- :class="{active:showCheckBox}" -->
  <div class="images overflow_scroll">
    <!-- <div v-if="showInfo" class="notice_info">
      <van-icon name="info" color="#1678FF" class="mr16" /> 
      单击图片可预览，长按图片可多选。
      <i class="iconfont hxb-icon_x" @click="showInfo=false"></i>
    </div> -->

    <van-grid :gutter="5" column-num="3">
      <van-grid-item v-for="(item,index) in imgList" :key="item.id">
        <div class="image_box" >
          <!-- <van-checkbox v-if="showCheckBox" v-model="item.checked" shape="square" /> -->
           <!--长按开始多选 v-longPress="longEvent" -->
          <van-image fit="cover" :src="item.content?ossUrl+item.content:'/img/avatar.png'" @click="handlePreview(index)"/>
          <p class="img_title">{{item.title}}</p>
        </div>
      </van-grid-item>
    </van-grid>
    <preview-img ref="previewImg" v-if="dialogs.previewImg" :dataList="imgList" :images="images" :current="current" @sendMsg="sendMsg" @modalClose="modalClose" />

    <!-- <van-button v-if="showCheckBox" class="send_btn_long" type="primary" @click="batchSendMsg" :loading="loading">发送</van-button> -->
  </div>
</template>
<script>
import { Icon,Grid, GridItem,Checkbox,Button,Image as VanImage } from 'vant'
import { reactive,toRefs,watchEffect  } from 'vue'
import {ossUrl} from '@/utils/urls'
import {sendChatMessage} from '@/utils/weChatConfig'
import {longPress} from '@/directive/longPress'
import previewImg from './previewImg'
// import notify from '@/vant/notify'
export default {
  props:{
    dataList:{
      required:true,
      type:Array,
      default:()=>[]
    }
  },
  directives:{longPress},
  components:{
    [Icon.name]:Icon,
    [Grid.name]:Grid,
    [GridItem.name]:GridItem,
    [Checkbox.name]:Checkbox,
    [Button.name]:Button,
    [VanImage.name]:VanImage,
    previewImg
  },
  setup(props) {
    const state=reactive({
      checked:false,
      ossUrl,
      showInfo:true,
      // showCheckBox:false,
      loading:false,
      dialogs:{
        previewImg:false
      },
      images:[],
      current:0,
      previewImg:null,
      imgList:[]
    })
    watchEffect(() => {
      state.imgList=props.dataList.map(item=>{
        return {
          ...item,
          checked:false
        }
      })
    })
/*     const longEvent=()=>{
      state.showCheckBox=!state.showCheckBox
    } */
    const handlePreview=(index)=>{
      state.current=index
      state.images=props.dataList.map(item=>ossUrl+item.content)
      state.dialogs.previewImg=true
    }
/*     const sendMsg=(element)=>{
      let promises=[]
      state.loading=true
      element.forEach(async item=>{
        let p =await sendChatMessage('image',item.content,item.title)
        promises.push(p)
      })
      Promise.all(promises).then(()=>{
        state.loading=false
        state.previewImg.loading=false
      })
    }
    const batchSendMsg=()=>{
      const checkedImgs=[]
      state.imgList.forEach(item=>{
        if(item.checked){
          checkedImgs.push(item)
        }
      })
      if(!checkedImgs||!checkedImgs.length){
        notify.error('请选择图片')
        return
      }
      sendMsg(checkedImgs)
    } */
    const sendMsg=(element)=>{
      state.loading=false
      sendChatMessage('image',element.content,element.title).then(()=>{
        state.previewImg.loading=false
      })
    }
    const modalClose=()=>{
      for(let key in state.dialogs){
        state.dialogs[key]=false
      }
    }

    return {
      ...toRefs(state),
      // longEvent,
      // batchSendMsg,
      sendMsg,
      handlePreview,
      modalClose
    }
  }
}
</script>
<style lang="less" scoped>
  .images{
    :deep(.send_btn_long){
      width: calc(~"100% - 64px");
      height: 88px;
      font-size: 34px;
      background: #1678ff;
      border-radius: 44px;
      position: fixed;
      bottom: 32px;
      left: 32px;
      right: 32px;
    }
    .notice_info{
      padding: 18px 20px;
      color: #1678FF;
      background: rgba(22,120,255,0.10);
      position: relative;
      border-radius: 12px;
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-bottom: 16px;

      .hxb-icon_x{
        position: absolute;
        color: #1678FF;
        font-size: 24px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    :deep(.van-grid){
      padding-left: 0 !important;
      user-select: none;
      .van-grid-item__content{
        padding: 0
      }
    }
    .image_box{
      position: relative;
      width: 218px;
      height: 218px;
      :deep(.van-image){
        width: 100%;
        height: 100%;
        img{
          pointer-events: none;
        }
      }
      :deep(.van-checkbox__icon){
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 32px;
        color: #fff;
        z-index:1;
        .van-icon{
          border-radius: 6px;
        }
      }
      .img_title{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 8px;
        box-sizing: border-box;
        font-size: 24px;
        line-height: 34px;
        color: #fff;
        background: rgba(0,0,0,0.20);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>