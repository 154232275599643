<!--
 * @Author: liyao
 * @Date: 2021-05-27 10:11:39
 * @LastEditTime: 2021-06-08 12:09:35
 * @LastEditors: Please set LastEditors
 * @Description:图片预览
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\contentEngine\previewImg.vue
-->
<template>
<div class="preview_img">
  <van-image-preview v-model:show="show" :images="images" :start-position="index" @change="onChange" @close="onClose">
    <template v-slot:index>{{currentObj.title}}</template>
    <template v-slot:cover>
      <div class="preview_bottom">
        <div class="img_page">{{index+1}}/{{images.length}}</div>
        <van-button type="primary" @click="sendMsg" :loading="loading">发送</van-button>
      </div>
    </template>
  </van-image-preview>
</div>
</template>
<script>
import { reactive,toRefs,computed } from 'vue'
import { ImagePreview ,Button} from 'vant'
export default {
  components:{
    [ImagePreview.Component.name]:ImagePreview.Component,
    [Button.name]:Button
  },
  emits:['sendMsg','modalClose'],
  props:{
    dataList:{
      type:Array,
      default:()=>[]
    },
    images:{
      type:Array,
      default:()=>[]
    },
    current:{
      type:Number,
      default:0
    }
  },
  setup(props,context) {
    const state=reactive({
      show: true,
      index:props.current,
      loading:false
    })
    const onChange=(index)=>{
      state.index=index
    }
    const onClose=()=>{
      context.emit('modalClose')
    }
    const currentObj=computed(()=>{
      return props.dataList[state.index]
    })
    const sendMsg=()=>{
      state.loading=true
      context.emit('sendMsg',currentObj.value)
    }
    return {
      ...toRefs(state),
      currentObj,
      onChange,
      onClose,
      sendMsg
    }
  }
}
</script>
<style lang="less" scoped>
.preview_img{
  :deep(.van-image-preview__cover){
      left: 32px;
      right: 32px;
      bottom: 24px;
      top: auto;
      .preview_bottom{
        display: flex;
        justify-content: space-between;
        .img_page{
          padding: 10px 20px;
          color: #fff;
          background-color: #000;
          border-radius: 8px;
        }
        .van-button{
          background: #1678ff;
          border-radius: 8px;
          font-size: 30px;
          height: auto;
          width: 128px;
        }
      }
    }

}
</style>